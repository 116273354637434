<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            {{ createForm ? 'Create External User' : 'Update External User' }}
            <v-spacer />
            <v-btn
                v-if="!createForm && !isEditing"
                depressed
                color="primary"
                rounded
                icon
                class=""
                @click="resendEmail"
            >
                <v-icon>mdi-send</v-icon>
            </v-btn>
            <v-btn
                v-if="!createForm && user.role != 'Project Collaborator'"
                depressed
                color="primary"
                rounded
                icon
                class=""
                @click="update"
            >
                <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <v-btn
                v-if="!createForm"
                depressed
                rounded
                color="primary"
                @click="openDeleteDialog"
                icon
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-card-title>
        <v-dialog
            v-model="deleteDialog"
            :retain-focus="false"
            persistent
            max-width="400px"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5"
                    >Delete External User</v-card-title
                >
                <v-card-text>
                    Are you sure you want to delete this external user?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="deleteDialog = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteExternalUser"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                :disabled="!createForm && !isEditing"
                class="mb-2"
            >
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="user.name"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required]"
                            prepend-icon="mdi-account-tie"
                            label="Name *"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="user.identification"
                            :readonly="!createForm && !isEditing"
                            prepend-icon="mdi-card-account-details"
                            label="Identification"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" class="pt-0">
                        <v-text-field
                            v-model="user.email"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.email]"
                            prepend-icon="mdi-email"
                            label="Email *"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 mt-3">
                        <vue-tel-input
                            v-model="user.phone"
                            mode="international"
                            :inputOptions="inputOptions"
                            :disabled="!createForm && !isEditing"
                            validCharactersOnly
                        />
                    </v-col>
                </v-row>
                <v-row class="mt-0">
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="password"
                            type="password"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required]"
                            prepend-icon="mdi-lock"
                            label="Password *"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="repeatPassword"
                            type="password"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required, rules.matchPassword]"
                            prepend-icon="mdi-lock-check"
                            label="Repeat Password *"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" class="pt-0 mt-2">
                        <v-select
                            class="mt-n2"
                            :readonly="!createForm && !isEditing"
                            :items="roleOptions"
                            :rules="[rules.required]"
                            v-model="user.role"
                            prepend-icon="mdi-cog"
                            label="Role *"
                            required
                        />
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 mt-0">
                        <v-text-field
                            v-model="user.hourlyPrice"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required, rules.validDollarAmount]"
                            prepend-icon="mdi-currency-usd"
                            label="Hourly Price *"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-0" cols="12" sm="6">
                        <v-select
                            :readonly="!createForm && !isEditing"
                            :items="languages"
                            :rules="[rules.required]"
                            v-model="user.language"
                            prepend-icon="mdi-account-tie-voice"
                            label="Language *"
                            required
                        />
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="6">
                        <v-text-field
                            v-model="user.notes"
                            hide-details
                            :readonly="!createForm && !isEditing"
                            prepend-icon="mdi-note-multiple"
                            label="Notes"
                        />
                    </v-col>
                </v-row>
                <v-switch
                    v-model="user.active"
                    label="Active"
                    hide-details
                    :input-value="true"
                    class="my-2"
                ></v-switch>
            </v-form>

            <small>* indicates required field</small>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                v-if="createForm"
                text
                color="primary"
                :loading="loading"
                :disabled="!valid"
                @click="saveExternalUser"
            >
                Save & Send Email
            </v-btn>
            <v-btn
                :disabled="!valid || Object.keys(userDiff) == 0"
                v-if="isEditing"
                text
                color="red"
                :loading="loading"
                @click="saveExternalUser"
            >
                {{ userDiff.email ? 'Save & Send Email' : 'Save' }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations } from 'vuex'
import { objDiff } from '@/helpers/objDiff.js'
import cryptoJs from 'crypto-js'
export default {
    name: 'ExternalUserForm',
    props: {
        user: {
            type: Object,
            default() {
                return {}
            },
        },
        createForm: {
            type: Boolean,
            required: true,
        },
        projectId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            password: null,
            repeatPassword: null,
            image: this.user.profilePicURL,
            languages: ['English', 'Spanish'],
            inputOptions: {
                placeholder: 'Phone',
            },
            isEditing: false,
            editing: null,
            editingIndex: -1,
            deleteDialog: false,
            index: null,
            company: JSON.parse(localStorage.getItem('company')),
            loading: false,
            error: false,
            errorMsg: null,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
                number: () => this.validNumber || 'Not a valid number',
                validDollarAmount: value =>
                    /^\d+(\.\d{1,2})?$/.test(value) ||
                    'Enter a valid amount (maximum 2 decimals)',
                city: v => /^[A-Za-z ]+$/.test(v) || 'Not a valid input',
                matchPassword: () =>
                    this.password === this.repeatPassword ||
                    'No password match',
            },
            deleteError: false,
            originalUser: {},
            roleOptions: [
                'Superintendent',
                'Engineer',
                'Contractor',
                'Inspector',
                'Designer',
                'Safety',
                'General Worker',
            ],
        }
    },
    computed: {
        userDiff: function() {
            if (!this.createForm) {
                return objDiff(this.originalUser, this.user)
            } else {
                return null
            }
        },
    },
    async created() {
        if (!this.createForm) {
            this.phone = _.cloneDeep(this.user.phone)
        } else {
            this.user.active = true
        }
    },
    async mounted() {
        this.originalUser = _.cloneDeep(this.user)
        if (this.user.password) {
            this.password = this.user.password
            this.repeatPassword = this.user.password
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openDeleteDialog() {
            this.deleteDialog = true
        },
        closeDialog() {
            this.$refs.form.reset()
            this.error = false
            this.user.phone = ''
            this.errorMsg = null
            this.isEditing = false
            this.$emit('closeDialog')
        },
        update() {
            this.isEditing = !this.isEditing
        },
        saveExternalUser: async function() {
            if (this.createForm) {
                await this.createExternalUser()
            } else {
                await this.updateExternalUser()
            }
        },
        async updateExternalUser() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                if (this.user.phone) {
                    this.user.phone = this.user.phone.replace(/ /g, '')
                }
                if (this.user.password != this.password) {
                    this.user.password = cryptoJs.AES.encrypt(
                        this.password,
                        process.env.VUE_APP_ENCRYPTION_PHRASE
                    ).toString()
                    this.userDiff.password = this.user.password
                }
                await API.updateExternalUser({
                    projectId: this.projectId,
                    userId: this.user.id,
                    ...this.userDiff,
                })
                this.$emit('replaceExternalUser', this.user)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.image = null
            }
        },
        async createExternalUser() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                if (this.user.phone) {
                    this.user.phone = this.user.phone.replace(/ /g, '')
                }
                Object.keys(this.user).forEach(key => {
                    if (this.user[key] === undefined || this.user[key] === '') {
                        delete this.user[key]
                    }
                })
                this.user.hourlyPrice = String(this.user.hourlyPrice)
                this.user.notes = !this.user.notes ? '' : this.user.notes
                this.user.identification = !this.user.identification
                    ? ''
                    : this.user.identification
                this.user.projectId = this.projectId
                this.user.password = cryptoJs.AES.encrypt(
                    this.password,
                    process.env.VUE_APP_ENCRYPTION_PHRASE
                ).toString()
                const newExternalUser = await API.createExternalUser(this.user)
                this.$emit('addExternalUser', newExternalUser)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.image = null
            }
        },

        async deleteExternalUser() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                await API.deleteExternalUser({
                    projectId: this.projectId,
                    userId: this.user.id,
                })
                this.$emit('removeExternalUser', this.user)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        async resendEmail() {
            try {
                this.loading = true
                await API.sendTimesheetEmail({
                    projectId: this.projectId,
                    userId: this.user.id,
                })
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.vue-tel-input {
    border: none;
    border-radius: 0px;
    font-size: 16px;
}
.vue-tel-input.disabled {
    opacity: 0.5;
}

.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: 'primary';
    color: 'primary';
}
</style>
